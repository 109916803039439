import { Link } from "react-router-dom"
import "./AboutContentStyles.css"
import React1 from "../assets/react1.jpg"
import React2 from "../assets/react2.webp"


import React from 'react'

const AboutContent = () => {
    return (
        <div className="about">
            <div className="left">
                <iframe
                    width="100%" 
                    height="100%"
                    // width="100%"
                    // height="100%"
                    src="https://www.youtube.com/embed/JSw-35456_M"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen>
                </iframe>
                <br></br>
                <h1>Who Am I?</h1>
                <p>Cended, a Detroit-based artist, crafts his music as a reflection of the profound experiences he navigates in the journey of life.
                    With a unique blend of soulful melodies and introspective lyricism,
                    Cended's soundscapes resonate with the raw emotions and authentic stories woven into the fabric of urban life.
                    Drawing inspiration from sunsets, walks in downtown, and the various landscapes in Michigan; His compositions serve as a poignant narrative of resilience,
                    hope, and the complexities of human experiences.
                </p>
            </div>
            {/* <Link to="/contact">
            <button className="btn">CONTACT</button>
        </Link> */}
            <div className="right">
                <div className="img-container">
                    {/* <div className="img-stack top">
                    <img className="img" alt="true" src={React1}/>
                </div> */}
                    {/* <div className="img-stack bottom">
                    <iframe></iframe>
                </div> */}
                    {/* JSw-35456_M */}
                    {/* https://youtube.com/shorts/yan7Ua--Xzg */}

                </div>
            </div>
        </div>
    )
}

export default AboutContent