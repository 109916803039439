import proj1 from "../assets/project1.png";
import proj2 from "../assets/project2.png";
import proj3 from "../assets/project3.png";

const ProjectCardData = 
[
    {
        imgsrc: proj1,
        title: "Ecommerce Website Design",
        text: "Some E commerceText",
        view: "https://www.youtube.com/watch?v=L2yizKR2koU",
        source: "https://www.youtube.com"
    },
    {
        imgsrc: proj2,
        title: "Ecommerce Website Design",
        text: "Some E commerceText",
        view: "https://www.youtube.com/watch?v=L2yizKR2koU",
        source: "https://www.youtube.com"
    },
    {
        imgsrc: proj3,
        title: "Ecommerce Website Design",
        text: "Some E commerceText",
        view: "https://www.youtube.com/watch?v=L2yizKR2koU",
        source: "https://www.youtube.com"
    }
];

export default ProjectCardData;