import "./FooterStyles.css"

import React from 'react'

import { FaDiscord, FaHome, FaInstagram, FaKickstarter, FaMailBulk, FaPhone, FaSoundcloud, FaSpotify, FaTiktok, FaTwitch, FaTwitter, FaYoutube } from "react-icons/fa"

const Footer = () => {
    return (
        <div className="footer">
            <div class="grid-container">
                <div class="grid-item">
                    <a href="https://twitter.com/cendedmusic" className="item">
                        <FaTwitter size={20} style={{ color: "#fff" }} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://open.spotify.com/artist/2uH84hWA59j4AKNzi0g5g1?si=p912st2TRCWIHBiXRw3-0w" className="item">
                        <FaSpotify size={20} style={{ color: "#fff" }} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://soundcloud.com/cendedmusic?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="item">
                        <FaSoundcloud size={20} style={{ color: "#fff" }} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://www.instagram.com/cendedmusic" className="item">
                        <FaInstagram size={20} style={{ color: "#fff"}} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://www.tiktok.com/@cendedmusic?_t=8kBbxzE5Mxn&_r=1" className="item">
                        <FaTiktok size={20} style={{ color: "#fff" }} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://www.youtube.com/channel/UCY-7idPtNnxYWoWp31PiNzA" className="item">
                        <FaYoutube size={20} style={{ color: "#fff"}} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://kick.com/cendedmusic" className="item">
                        <FaKickstarter size={20} style={{ color: "#fff"}} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://www.twitch.tv/cendedmusic" className="item">
                        <FaTwitch size={20} style={{ color: "#fff" }} />
                    </a>
                </div>
                <div class="grid-item">
                    <a href="https://discord.gg/7jUuAdw4" className="item">
                        <FaDiscord size={30} style={{ color: "#fff" }} />
                    </a>
                </div>
            </div>
            <div class="grid-item">
                <p><FaMailBulk size={20} style={{ color: "#fff", marginRight:"1rem"}} />cendedmusic@gmail.com</p>
            </div>
            {/* <div className="footer-container">
                <div>
                <a href="https://twitter.com/cendedmusic" className="item">
                    <FaTwitter size={30} style={{ color: "#fff", marginRight: "3rem" }}  />
                </a>
                <a href="https://www.instagram.com/cendedmusic" className="item">
                    <FaInstagram size={30} style={{ color: "#fff", marginRight: "3rem" }} />
                </a>
                <a href="https://www.tiktok.com/@cendedmusic?_t=8kBbxzE5Mxn&_r=1" className="item">
                    <FaTiktok size={30} style={{ color: "#fff", marginRight: "3rem" }} />
                </a>
                <a href="https://www.youtube.com/channel/UCY-7idPtNnxYWoWp31PiNzA" className="item">
                    <FaYoutube size={30} style={{ color: "#fff", marginRight: "3rem" }} />
                </a>
                <a href="https://kick.com/cendedmusic" className="item">
                    <FaKickstarter size={30} style={{ color: "#fff", marginRight: "3rem" }} />
                </a>
                <a href="https://www.twitch.tv/cendedmusic" className="item">
                    <FaTwitch size={30} style={{ color: "#fff", marginRight: "3rem" }} />
                </a>
                <a href="https://discord.gg/7jUuAdw4" className="item">
                    <FaDiscord size={30} style={{ color: "#fff", marginRight: "3rem" }} />
                </a>
                </div>
            </div> */}
        </div>
    )
}

// <div className="left">
// <div className="location">
//     <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
//     <div>
//         <p> 123 Housing Society</p>
//         <p> Usa</p>
//     </div>
// </div>
// <div className="phone">
//     <h4><FaPhone size={20} style={{ color: "#fff", marginRight: "2rem" }} />248-765-2396</h4>
// </div>
// <div className="email">
//     <h4><FaMailBulk size={20} style={{ color: "#fff", marginRight: "2rem" }} />cendedmusic@gmail.com</h4>
// </div>
// </div>
// <div className="right">
// <h4>About the company</h4>
// <p> something hereaskldjfalsdkfjalksdfjlaksdfjlaksdjflaksdjflakjalkjasdlkfa</p>
// <div className="social">
//     <FaTwitter size={30} style={{ color: "#fff", marginRight: "1rem" }}  />
//     <FaInstagram size={30} style={{ color: "#fff", marginRight: "1rem" }} />
//     <FaTiktok size={30} style={{ color: "#fff", marginRight: "1rem" }} />
//     <FaYoutube size={30} style={{ color: "#fff", marginRight: "1rem" }} />
// </div>
// </div>

export default Footer