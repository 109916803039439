import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImg from '../components/Heroimg'
import Work from '../components/Work'
import AboutContent from '../components/AboutContent'

const Home = () => {

  return (
    <div>
      <Navbar/>
      <HeroImg/>
      {/* <Work/> */}
      <AboutContent/>
      <Footer/>
    </div>
  )
}

export default Home