import "./HeroImgStyles.css";

import React from 'react';
import PurpleSunset from "../assets/Logo/PurpleSunset.jpg";
import { Link } from "react-router-dom";
import CendedWhite from "../assets/Logo/CENDED Logo white.png";
import CendedIcon from "../assets/CendedIcon.png"

const Heroimg = () => {
  return (
    <div className="hero">
      <div className="mask">
        {/* <div className="Icon">
          <img src={CendedIcon} alt="IntroImg"  width={5} height={5}/>
        </div> */}
        {/* <img className="into-img" src={PurpleSunset} alt="IntroImg" /> */}
        <video className="into-img" autoPlay loop muted playsInline>
          <source src="https://d1vbuy22drkn3e.cloudfront.net/sunset.mp4" type='video/mp4' />
        </video>
      </div>
      <div className="content">
        <div className="icon">
          <img src={CendedIcon} alt="IntroImg" width={300} height={200} />
        </div>
        <iframe
          className="player"
          // height="250px"
          title="SoundCloud Player"
          width="100%"
          height="100%"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/cendedmusic&amp;&auto_play=true;color=D2B4DE;sharing=true;single_active=true"
          allowtransparency="true"
        >
        </iframe>
      </div>
    </div>
  );
};


export default Heroimg
{/* 
<p>HI, IM A FREELANCER.</p>
        <h1>React Developer</h1>
        <div>
            <Link to="/project" className="btn">Projects</Link>
            <Link to="/contact" className="btn-light">Contact</Link>
        </div></div>
         */}